<template>
  <div class="help-and-support">
    <h1>Help &amp; Support</h1>
    <b-row class="mb-3" v-for="(group, groupIndex) in groupedLinks" :key="`group-${groupIndex}`">
      <b-col cols="12" :md="group.length * 3">
        <b-card-group deck>
          <b-card
              v-for="(link, linkIndex) in group" :key="`link-${linkIndex}`"
              class="text-center"
              :img-src="`/help/${link.image}.png`"
              :img-alt="link.image"
              img-top
          >
            <div class="d-flex flex-column align-items-center justify-content-between h-100">
            <h5>{{ link.title }}</h5>
              <template v-if="link.link.startsWith('/')">
                <router-link :to="link.link" class="btn btn-outline-success" >Find Out More</router-link>
              </template>
              <template v-else>
                <a :href="link.link" target="_blank" class="btn btn-outline-success" >Find Out More</a>
              </template>
            </div>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'HelpAndSupport',
  metaInfo() {
    return {
      title: 'Help & Support | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  },
  computed: {
    groupedLinks() {
      return this.links.reduce((acc, link, index) => {
        if (index % 4 === 0) {
          acc.push([])
        }
        acc[acc.length - 1].push(link)
        return acc
      }, [])
    }
  },
  data() {
    return {
      links: [
        {
          title: 'Help with the Cost of Living',
          image: 'cost_of_living',
          link: 'https://www.citizensadvice.org.uk/debt-and-money/get-help-with-the-cost-of-living/'
        },
        {
          title: 'Help with Healthcare Travel Costs',
          image: 'healthcare_travel_costs',
          link: 'https://www.nhs.uk/nhs-services/help-with-health-costs/healthcare-travel-costs-scheme-htcs/'
        },
        {
          title: 'Help with Energy Bills',
          image: 'energy_bills',
          link: 'https://helpforhouseholds.campaign.gov.uk/help-with-your-bills/'
        },
        {
          title: 'Benefit Calculator',
          image: 'benefit_calculator',
          link: 'https://www.entitledto.co.uk'
        },
        {
          title: 'Stop Loan Sharks',
          image: 'loan_sharks',
          link: 'https://www.stoploansharks.co.uk/what-is-a-loan-shark/'
        },
        {
          title: 'Help with Funeral Expenses',
          image: 'funeral',
          link: 'https://www.gov.uk/funeral-payments/eligibility'
        },
        {
          title: 'Help with Childcare Costs',
          image: 'childcare',
          link: '/help-and-support/childcare-costs'
        },
        {
          title: 'Help with Prescription Costs',
          image: 'prescription',
          link: 'https://www.gov.uk/help-nhs-costs'
        },
        {
          title: 'Help with Food Costs',
          image: 'food_costs',
          link: 'https://www.breadandbutterthing.org/'
        },
        {
          title: 'Help with Universal Credit',
          image: 'universal_credit',
          link: 'https://www.citizensadvice.org.uk/benefits/universal-credit/what-youll-need-to-do-on-universal-credit/check-if-a-change-affects-your-universal-credit/'
        },
        {
          title: 'Help with Gambling',
          image: 'gambling',
          link: 'https://www.gamcare.org.uk'
        }
      ]
    }
  }
}
</script>

<style>
</style>
